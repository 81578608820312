/* You can add global styles to this file, and also import other style files */
@import "styles/material.less";
:root{
    --margin : 3rem;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: ApercuPro, "Helvetica Neue", sans-serif;
}

@font-face {
    font-family: "ApercuPro";
    src: local("ApercuPro"), url(./assets/fonts/ApercuPro-Regular.otf) format("truetype");
}

@font-face {
    font-family: "Grotesk";
    src: local("Grotesk"), url(./assets/fonts/EKOGrotesk-Regular.otf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: "ApercuPro" !important;
}

.logo-synergy {
    width: 35% !important;
}

//Loader Class
.loader-position {
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        height: 100px !important;
    }
}

//Dialog Close Button
.close {
    background-color: transparent !important;
    border: 0px solid #e7e7e7 !important;
}

//Input Leabels Font Size
.input-size {
    font-size: 20px !important;
}

//Button
.submit {
    padding: 15px 50px 15px 50px;
    background-color: #0069AD;
    color: white;
    border: 0px solid #e7e7e7;
    border-radius: 35px;
    font-size: 18px;
    transition: 1s;
    // width: 100% !important;
}

.submit:hover {
    color: #b2b2b2;
    transition: 1s;
}

//inactive button
.inactive-submit {
    padding: 15px 50px 15px 50px;
    background-color: #9ba19d;
    color: #000;
    border: 0px solid #e7e7e7;
    border-radius: 35px;
    font-size: 18px;
    transition: 1s;
    width: 100% !important;
}

// .inactive-submit:hover {
//     color: #b2b2b2;
//     transition: 1s;
// }


//Error Massage
.error-massage {
    padding-bottom: 20px;
    color: #f44336;
    font-size: 18px;
}

//For Loaders Errors
.pad-bot {
    padding-bottom: 0px !important;
}

//Number And Line For Steps Add Card
.steps-title {
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: 32px !important;
    font-weight: bold;
}

.circle-step {
    padding-left: 15px;
    padding-right: 15px;
    cursor: default !important;
    pointer-events: none;
    opacity: 0.3;
    background-color: #0069AD;
    border-radius: 30px;
    font-size: 22px;
}

.line {
    width: 100%;
    margin-bottom: 22px;
    opacity: 0.6;
    border-bottom: 2px solid #C6C6C6;
}

//For Succses Massages
.succses-massage {
    padding-top: 15px;
    color: #569e68;
    font-size: 18px;
}