/* Form Under Line BG Color*/
.mat-focused .mat-form-field-ripple {
  background-color: #0069AD !important;
}

/* Form Under Line BG Color Hover*/
.mat-form-field-ripple {
  background-color: #0069AD;
}

/* Write Line Inside Label */
.mat-input-element {
  caret-color: #0069AD;
}

/* Inside Write Bar */
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: .0em .0em 1em .0em;
}

/* Text Area */
textarea.mat-input-element {
  font-size: 20px;
}

/* Check Box Border Color*/
.mat-checkbox-frame {
  border-color: #909190;
}

/* Check Box Label Size */
.mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 10px !important;
}

/* Check Box If Checked */
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0069AD !important;
}

/* Check Box Label For Responsive */
.mat-checkbox-layout {
  white-space: break-spaces !important;
}

//Loader Color
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #0069AD !important;
}

//Passwrod Bar Strength
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: orange;
}

.mat-progress-bar-fill::after {
  background-color: green;
}

//Mat Dialog 
.mat-dialog-container {
  height: unset !important;
  overflow-x: hidden !important;
  z-index: 10000 !important;
  margin-top: 12%;
  margin-bottom: 3% !important;
}

//Arrow Size Fron Mat Expention Panel
.mat-expansion-indicator::after {
  width: 20px;
  height: 20px;
}

//Color For Selected Item Mat Form Field
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000000;
}

//Arrow  Color For Selected Mat Form Field
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0069AD;
}

//Arrow For Selected Mar Form Field
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  padding-top: 18px;
}

//Padding Width For Mat-Expension Panel In My-Charging-Records
.mat-expansion-panel-body {
  padding: 0 10px 16px !important;
}

//Styles For Mat Snack Bar
.mat-snack-bar-container {
  background: white;
  color: black;
}

.mat-button {
  color: black;
}

//CDRS Pagination
.ngx-pagination a {
  border-radius: 30px !important;
}

.ngx-pagination .current {
  background: #fde6cb !important;
  border-radius: 15px;
  color: #000000 !important;
  font-size: 17px !important;
}
.mat-expansion-panel {
  box-shadow: none !important;
}
.mat-expansion-panel > .mat-expansion-panel-header{
    box-shadow: none;
    border-radius: 16px;
    background: none !important;
}

//Smaller 767 Screens
@media screen and (max-width: 767px) {

  //Mat Dialog 
  .mat-dialog-container {
    overflow-y: auto !important;
    margin-top: 25%;
  }
}

@media screen and (max-height: 790px) {

  //Mat Dialog 
  .mat-dialog-container {
    overflow-y: auto !important;
  }
}